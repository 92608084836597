exports.buildCourseBlock = (item) => {
  let instructorPidm = ''
  let instructorName = ''
  let instructorEmail = ''
  let instructorEmailSent = false
  if ('instructors' in item && item.instructors.length > 0) {
    if ('pidm' in item.instructors[0]) instructorPidm = item.instructors[0].pidm
    if ('directoryId' in item.instructors[0]) {
      instructorName = item.instructors[0].directoryId.name.last + ', ' + item.instructors[0].directoryId.name.first
      instructorEmail = item.instructors[0].directoryId.email
    }
  } else if ('instructor' in item && typeof (item.instructor) === 'object' && 'pidm' in item.instructor && 'name' in item.instructor && 'email' in item.instructor) {
    instructorPidm = item.instructor.pidm
    instructorName = item.instructor.name
    instructorEmail = item.instructor.email
    instructorEmailSent = item.instructor.emailSent
  }
  let times = []
  if (item.meetingBase.length > 0) {
    item.meetingBase.forEach(({ days, startTime, endTime, room }) => {
      if (room != null) {
        times.push(days + ' ' + startTime + ' - ' + endTime + ', ' + room)
      }
    })
  }
  let obj = {
    title: item.title,
    action: item.action || false,
    instructorPidm,
    instructorName,
    instructorEmail,
    instructorEmailSent,
    status: item.status || null,
    regStatus: item.regStatus,
    crn: item.crn,
    credits: item.credits,
    meetingBase: item.meetingBase,
    times: times.join('; ')
  }
  if ('gradeMode' in item) {
    obj.gradeMode = item.gradeMode
  }
  return obj
}

exports.addToCalendar = (item, isOriginal, calStart, weeklyCalArr) => {
  // if ('credits' in item && !isNaN(item.credits)) totalCredits.value += item.credits
  item.meetingBase.forEach(({ days, startTime, endTime, room }) => {
    for (let j = 0; j < days.length; j++) {
      let startDate = new Date(calStart + ' ' + startTime.substr(0, startTime.length - 2))
      if (startTime.substr(-2) === 'pm' && startDate.getHours() !== 12) startDate.setHours(startDate.getHours() + 12)
      let endDate = new Date(calStart + ' ' + endTime.substr(0, endTime.length - 2))
      if (endTime.substr(-2) === 'pm' && endDate.getHours() !== 12) endDate.setHours(endDate.getHours() + 12)
      switch (days[j]) {
        case 'M':
          startDate.setDate(startDate.getDate() + 1)
          endDate.setDate(endDate.getDate() + 1)
          break
        case 'T':
          startDate.setDate(startDate.getDate() + 2)
          endDate.setDate(endDate.getDate() + 2)
          break
        case 'W':
          startDate.setDate(startDate.getDate() + 3)
          endDate.setDate(endDate.getDate() + 3)
          break
        case 'R':
          startDate.setDate(startDate.getDate() + 4)
          endDate.setDate(endDate.getDate() + 4)
          break
        case 'F':
          startDate.setDate(startDate.getDate() + 5)
          endDate.setDate(endDate.getDate() + 5)
          break
        case 'S':
          startDate.setDate(startDate.getDate() + 6)
          endDate.setDate(endDate.getDate() + 6)
          break
      }
      let [ startMonth, startDay, startYear ] = startDate.toLocaleDateString().split('/')
      let start = startYear + '-' + (startMonth < 10 ? '0' : '') + startMonth + '-' + (startDay < 10 ? '0' : '') + startDay + ' ' + startDate.getHours() + ':' + (startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()
      let [ endMonth, endDay, endYear ] = endDate.toLocaleDateString().split('/')
      let end = endYear + '-' + (endMonth < 10 ? '0' : '') + endMonth + '-' + (endDay < 10 ? '0' : '') + endDay + ' ' + endDate.getHours() + ':' + (endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()
      weeklyCalArr.push({
        crn: item.crn,
        start,
        end,
        name: item.title,
        removed: ['DC', 'DD'].includes(item.regStatus),
        original: isOriginal || false
      })
    }
  })
}

/**
 * Builds a Date object to use in checking class conflicts; this is to be used after validating that the days already overlap so we use a generic day for all of the times.
 */
function buildClassDate (time) {
  // The time is in 12-hour format with am/pm; we need to strip the am/pm from the time before creating the Date object otherwise we get an error
  let dt = new Date('2020-08-01 ' + time.substr(0, time.length - 2))
  // Now we need to adjust the time if it is a pm time since it needs to be in 24-hour format;
  // So if the time has pm and it is not in the 12:00 hour, then add 12 (to turn 1:00pm into 13:00)
  if (time.substr(-2) === 'pm' && dt.getHours() !== 12) dt.setHours(dt.getHours() + 12)
  return dt
}
exports.buildClassDate = buildClassDate

exports.checkConflict = ({ days, startTime, endTime }, schedule, ignoreCrn) => {
  // Build date objects for the start and end of the class time for which we are checking conflicts
  let checkStart = buildClassDate(startTime)
  let checkEnd = buildClassDate(endTime)
  if (ignoreCrn) console.log('Checking conflict for ' + ignoreCrn)

  for (let i = 0; i < schedule.length; i++) {
    if (!('meetingBase' in schedule[i]) || schedule[i].action === 'drop' || schedule[i].crn === ignoreCrn) continue
    for (let j = 0; j < schedule[i].meetingBase.length; j++) {
      // check for overlap on the days
      for (let k = 0; k < days.length; k++) {
        if (schedule[i].meetingBase[j].days.search(days[k]) >= 0) {
          // overlapping on days; check for overlap on times
          let scStart = buildClassDate(schedule[i].meetingBase[j].startTime)
          let scEnd = buildClassDate(schedule[i].meetingBase[j].endTime)

          // If the class being checked starts before the scheduled class ends and ends after this class starts, then there is a conflict
          if (checkStart < scEnd && checkEnd > scStart) {
            return true
          }
        }
      }
    }
  }
  return false
}
